// @flow

import './index.less';

import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';

const ClientLogos = (): React$Node => (
  <div className="ClientLogos">
    <StaticImage src="../../images/client-logos.png" alt="Client Logos" />
    <p>and more...</p>
  </div>
);

export default ClientLogos;
