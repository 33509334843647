// @flow

import './index.less';

import { Button } from 'antd';
import React from 'react';

import SabisuLogo from '_components/SabisuLogo';

const Navbar = (): React$Node => (
  <div className="Navbar">
    <SabisuLogo />
    <nav>
      <a href="https://690knrgrpn0.typeform.com/to/p3nF7mqI" target="_blank" rel="noreferrer">
        <Button size="large" type="primary">
          Reach Out
        </Button>
      </a>
    </nav>
  </div>
);

export default Navbar;
