// @flow

import './index.less';

import { Button, Col, Row, Typography } from 'antd';
import React from 'react';

const { Link } = Typography;

const ContactUs = (): React$Node => (
  <Row className="ContactUs">
    <Col span={24}>
      <h2>Ready to chat and get started?</h2>
      <p>
        We’re very approachable and there’s no obligations! Simply fill out this form as best you can, and we’ll know
        what to do to help you from there. You can also reach out via{' '}
        <Link href="mailto:hello@sabisu.dev">hello@sabisu.dev</Link>
      </p>
      <a href="https://690knrgrpn0.typeform.com/to/p3nF7mqI" target="_blank" rel="noreferrer">
        <Button size="large" type="primary">
          Reach Out
        </Button>
      </a>
    </Col>
  </Row>
);

export default ContactUs;
