// @flow

import './index.less';

import {Layout} from 'antd';
import React from 'react';

import Navbar from '_components/Navbar';

const {Header, Footer, Content} = Layout;

type Props = {
  children: React$Node,
};

const AppLayout = ({children}: Props): React$Node => (
  <Layout>
    <Header>
      <Navbar />
    </Header>
    <Content>{children}</Content>
    <Footer>
      <p>Copyright &copy; 2022 Sabisu Pte Ltd </p>
    </Footer>
  </Layout>
);

export default AppLayout;
