// @flow

import './index.less';

import { Col, Row } from 'antd';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const USPs = (): React$Node => (
  <>
    <Row justify="center" align="middle" gutter={50} className="USPRow">
      {/* USP One */}
      <Col xs={24} sm={24} md={0} lg={2} xl={2}>
        &nbsp;
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <div className="USPImage">
          <StaticImage src="../../images/usp-image-1.png" alt="" />
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <div className="USPText">
          <h2>We work with you and your desired outcome in mind</h2>
          <p>
            Project requirements are unique and always evolving at various stages. From early stage to growth to getting
            data-driven for better decision-making, our team of senior product managers will work closely with you to
            narrow down your core problems and define the right outcomes to build towards.
          </p>
        </div>
      </Col>
      <Col xs={24} sm={24} md={0} lg={2} xl={2}>
        &nbsp;
      </Col>
    </Row>

    {/* USP Two */}
    <Row justify="center" align="middle" gutter={50} className="USPRow">
      <Col
        xs={{ span: 0, order: 1 }}
        sm={{ span: 0, order: 1 }}
        md={{ span: 0, order: 1 }}
        lg={{ span: 2, order: 1 }}
        xl={{ span: 2, order: 1 }}
      >
        &nbsp;
      </Col>
      <Col
        xs={{ span: 24, order: 3 }}
        sm={{ span: 24, order: 3 }}
        md={{ span: 12, order: 2 }}
        lg={{ span: 12, order: 2 }}
        xl={{ span: 12, order: 2 }}
      >
        <div className="USPText">
          <h2>Plan, build and iterate closely with our team</h2>
          <p>
            Every business and industry comes with their own set of challenges and quirks. We de-risk your development
            by working closely with you, your staff, and even your users. Code can be both complex and costly, so we
            make sure we’re fully aligned with you before we kick-off development.
          </p>
        </div>
      </Col>
      <Col
        xs={{ span: 24, order: 2 }}
        sm={{ span: 24, order: 2 }}
        md={{ span: 12, order: 3 }}
        lg={{ span: 8, order: 3 }}
        xl={{ span: 8, order: 3 }}
      >
        <div className="USPImage">
          <StaticImage src="../../images/usp-image-2.png" alt="" />
        </div>
      </Col>
      <Col
        xs={{ span: 0, order: 4 }}
        sm={{ span: 0, order: 4 }}
        md={{ span: 0, order: 4 }}
        lg={{ span: 2, order: 4 }}
        xl={{ span: 2, order: 4 }}
      >
        &nbsp;
      </Col>
    </Row>

    {/* USP Three */}
    <Row justify="center" align="middle" gutter={50} className="USPRow">
      <Col xs={24} sm={24} md={0} lg={2} xl={2}>
        &nbsp;
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <div className="USPImage">
          <StaticImage src="../../images/usp-image-3.png" alt="" />
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <div className="USPText">
          <h2>Stay updated with weekly check-ins as we develop</h2>
          <p>
            Once development commences, we continue to keep you involved on a consistent, weekly or bi-weekly basis as
            we build out your project. Alternatively, you can trust our experienced managers and teams, and we’ll only
            flag crucial decisions that need your input. We’re flexible either way.
          </p>
        </div>
      </Col>
      <Col xs={24} sm={24} md={0} lg={2} xl={2}>
        &nbsp;
      </Col>
    </Row>

    {/* USP Four */}
    <Row justify="center" align="middle" gutter={50} className="USPRow" style={{ marginBottom: '5em' }}>
      <Col
        xs={{ span: 0, order: 1 }}
        sm={{ span: 0, order: 1 }}
        md={{ span: 0, order: 1 }}
        lg={{ span: 2, order: 1 }}
        xl={{ span: 2, order: 1 }}
      >
        &nbsp;
      </Col>
      <Col
        xs={{ span: 24, order: 3 }}
        sm={{ span: 24, order: 3 }}
        md={{ span: 12, order: 2 }}
        lg={{ span: 12, order: 2 }}
        xl={{ span: 12, order: 2 }}
      >
        <div className="USPText">
          <h2>We’re there for you, even after you launch</h2>
          <p>
            Congratulations! Launching is the first important step of many milestones to come, and we’ll be there with
            you to make sure launch week goes as smooth as possible. We’re also here for you in case you’re ready for
            the next stages of your projects: more advanced features, refactoring, tackling your martech and data
            analytics stack, and more. Just reach out!
          </p>
        </div>
      </Col>
      <Col
        xs={{ span: 24, order: 2 }}
        sm={{ span: 24, order: 2 }}
        md={{ span: 12, order: 3 }}
        lg={{ span: 8, order: 3 }}
        xl={{ span: 8, order: 3 }}
      >
        <div className="USPImage">
          <StaticImage src="../../images/usp-image-4.png" alt="" />
        </div>
      </Col>
      <Col
        xs={{ span: 0, order: 4 }}
        sm={{ span: 0, order: 4 }}
        md={{ span: 0, order: 4 }}
        lg={{ span: 2, order: 4 }}
        xl={{ span: 2, order: 4 }}
      >
        &nbsp;
      </Col>
    </Row>
  </>
);

export default USPs;
