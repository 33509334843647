// @flow

import './index.less';

import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';

// import DividerDown from '_images/divider-down.png';
// import DividerLeft from '_images/divider-left.png';
// import DividerRight from '_images/divider-right.png';

type Props = {
  title: string,
};

const Divider = ({title = 'Title'}: Props): React$Node => (
  <>
    <div className="Divider">
      <StaticImage src="../../images/divider-left.png" alt="icon" layout="fixed" style={{flex: '0 0 auto'}} />
      <span className="DividerLine">&nbsp;</span>
      <StaticImage src="../../images/divider-down.png" alt="icon" layout="fixed" style={{flex: '0 0 auto'}} />
      <span className="DividerLine">&nbsp;</span>
      <StaticImage src="../../images/divider-right.png" alt="icon" layout="fixed" style={{flex: '0 0 auto'}} />
    </div>
    <div className="DividerTitle">{title}</div>
  </>
);

export default Divider;
