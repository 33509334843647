// @flow

import '_styles/index.less';
import React from 'react';

type Props = {
  element: Object,
};

export const wrapRootElement = ({element}: Props): React$Node => (
  <div className="app">
    <div className="appContainer">{element}</div>
  </div>
);

export default wrapRootElement;
