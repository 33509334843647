// @flow

import React from 'react';

import ClientLogos from '_components/ClientLogos';
import ContactUs from '_components/ContactUs';
import Divider from '_components/Divider';
import Hero from '_components/Hero';
import AppLayout from '_components/Layout';
import Team from '_components/Team';
import USPList from '_components/USPList';

const Index = (): React$Node => (
  <div>
    <AppLayout>
      <Hero />
      <Divider title="How We Work" />
      <USPList />
      <Divider title="Key People & Mission" />
      <Team />
      <Divider title="Our Experience" />
      <ClientLogos />
      <Divider title="Reach Out" />
      <ContactUs />
    </AppLayout>
  </div>
);

export default Index;
