// @flow

import './index.less';

import { Col, Row } from 'antd';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Hero = (): React$Node => (
  <Row justify="center" align="middle" gutter={50} className="HeroContainer">
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <div className="HeroUSP">
        <h1>Custom-built apps & systems, tailored to you</h1>
        <p>
          Our mission is to help you build and ship products for a fraction of the cost and effort of doing it yourself
          or in-house. Building a good product is expensive; tap on our collective experience so time and money don't
          work against you at early stages!
        </p>
      </div>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <div className="HeroImage">
        <StaticImage src="../../images/hero-image.png" alt="Hero Banner" />
      </div>
    </Col>
  </Row>
);

export default Hero;
