// @flow
import './index.less';

import anime from 'animejs';
import React, { useEffect } from 'react';

const text = 'sabisu.dev';

function detectMob() {
  return window.innerWidth <= 767;
}

const BASE_ANIMATION_SPEED = 50;

const SabisuLogo = (): React$Node => {
  useEffect(() => {
    document.querySelector('.ml11 .text-wrapper').style.opacity = 1;

    const textWrapper = document.querySelector('.ml11 .letters');
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    let cursorHeight = 32;
    let cursorWidth = 4;
    let cursorWidthFinal = 16;

    // adjust cursor size when in mobile mode
    if (detectMob()) {
      cursorHeight = 16;
      cursorWidth = 2;
      cursorWidthFinal = 6;
    }

    // the cursor appear animation
    anime
      .timeline({ loop: false })
      .add({
        targets: '.ml11 .line',
        height: [0, cursorHeight],
        easing: 'linear',
        duration: BASE_ANIMATION_SPEED * 7,
        delay: BASE_ANIMATION_SPEED * 5,
      })
      .add({
        targets: '.ml11 .line',
        opacity: [1, 0],
        easing: 'linear',
        duration: 1,
        delay: BASE_ANIMATION_SPEED * 3,
      })
      .add({
        targets: '.ml11 .line',
        opacity: [0, 1],
        easing: 'linear',
        duration: 1,
        delay: BASE_ANIMATION_SPEED * 3,
      })
      .add({
        targets: '.ml11 .line',
        opacity: [1, 0],
        easing: 'linear',
        duration: 1,
        delay: BASE_ANIMATION_SPEED * 3,
      })
      .add({
        targets: '.ml11 .line',
        opacity: [0, 1],
        easing: 'linear',
        duration: 1,
        delay: BASE_ANIMATION_SPEED * 3,
      })
      .add({
        targets: '.ml11 .line',
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 1,
        complete: () => {
          const letterWidth = document.querySelector('.ml11 .letter').getBoundingClientRect().width;

          const lineMoveAnimate = anime.timeline({ loop: false });
          for (let i = 0; i < text.length; i += 1) {
            lineMoveAnimate.add({
              targets: '.ml11 .line',
              translateX: [i === 0 ? 0 : letterWidth * i + 4, letterWidth * (i + 1) + 4],
              easing: 'linear',
              duration: 1,
              delay: BASE_ANIMATION_SPEED * 2,
            });
            if (i === text.length - 1) {
              lineMoveAnimate
                .add({
                  targets: '.ml11 .line',
                  width: [cursorWidth, cursorWidthFinal],
                  easing: 'easeOutExpo',
                  duration: BASE_ANIMATION_SPEED * 8,
                  delay: BASE_ANIMATION_SPEED,
                })
                .add({
                  targets: '.ml11 .line',
                  opacity: [1, 0],
                  easing: 'linear',
                  duration: 1,
                  delay: BASE_ANIMATION_SPEED,
                })
                .add({
                  targets: '.ml11 .line',
                  opacity: [0, 1],
                  easing: 'linear',
                  duration: 1,
                  delay: BASE_ANIMATION_SPEED * 3,
                });
            }
          }

          const textAnimate = anime.timeline({ loop: false });
          textAnimate.add({
            targets: '.ml11 .letter',
            opacity: [0, 1],
            easing: 'easeOutExpo',
            duration: 1,
            delay: (el, i) => BASE_ANIMATION_SPEED * 2 * (i + 1),
          });
        },
      });
  }, []);

  return (
    <div className="ml11">
      <span className="text-wrapper">
        <span className="line line1" />
        <span className="letters">{text}</span>
      </span>
    </div>
  );
};

export default SabisuLogo;
