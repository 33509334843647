// @flow

import './index.less';

import { Col, Row, Typography } from 'antd';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const { Link } = Typography;

const Team = (): React$Node => (
  <>
    <Row className="Team">
      <div className="Intro">
        <h2>Let us build for you, just as we do for ourselves</h2>
        <p>
          As a subsidary of{' '}
          <Link href="https://www.hajimari.co" target="_blank">
            Hajimari Labs
          </Link>
          , we don’t just focus on building software for clients.
        </p>
        <p>
          Our teams continue to build and scale our own products and businesses, and we distill all our lessons learnt
          across our people, maximising our knowledge and ability across domains to translate that into your very own
          business’s needs and success.
        </p>
      </div>
    </Row>

    <Row className="KeyPeople" gutter={[50, 20]}>
      {/* Darren */}
      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 8, order: 1 }}
        lg={{ span: 8, order: 1 }}
        xl={{ span: 8, order: 1 }}
      >
        <Row gutter={16}>
          <Col xs={6} sm={6} md={24} lg={24} xl={24}>
            <div className="Memoji">
              <StaticImage src="../../images/memoji-darren.png" alt="Darren Memoji" />
            </div>
          </Col>
          <Col xs={18} sm={18} md={24} lg={24} xl={24}>
            <div className="NameAndRole">
              <h4>Darren</h4>
              <span>Director, Projects</span>
            </div>
            <p className="ShortDesc">
              As a resourceful problem-solver, Darren helps businesses think deeply through their problems in search of
              cost-effective, unique and viable solutions.
            </p>
            {/* <div className="SocialLinks">
              <a href="https://www.linkedin.com/in/darrenmftay/" target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../images/icon-linkedin.png"
                  alt="icon"
                  layout="fixed"
                  style={{ flex: '0 0 auto', display: 'inline-block', marginRight: '1em' }}
                />
              </a>
              <a href="https://twitter.com/darrenmftay" target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../images/icon-twitter.png"
                  alt="icon"
                  layout="fixed"
                  style={{ flex: '0 0 auto', display: 'inline-block' }}
                />
              </a>
            </div> */}
          </Col>
        </Row>
      </Col>

      {/* Norman */}
      <Col
        xs={{ span: 24, order: 2 }}
        sm={{ span: 24, order: 2 }}
        md={{ span: 8, order: 2 }}
        lg={{ span: 8, order: 2 }}
        xl={{ span: 8, order: 2 }}
      >
        <Row gutter={16}>
          <Col xs={6} sm={6} md={24} lg={24} xl={24}>
            <div className="Memoji">
              <StaticImage src="../../images/memoji-chris.png" alt="Norman" />
            </div>
          </Col>
          <Col xs={18} sm={18} md={24} lg={24} xl={24}>
            <div className="NameAndRole">
              <h4>Norman</h4>
              <span>Director, Engineering</span>
            </div>
            <p className="ShortDesc">
              A seasoned veteran in product development, Norman directs multiple devteams with his proven methodology
              that ensures smooth development.
            </p>
            {/* <div className="SocialLinks">
              <a href="https://www.linkedin.com/in/christopher-cai/" target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../images/icon-linkedin.png"
                  alt="icon"
                  layout="fixed"
                  style={{ flex: '0 0 auto', display: 'inline-block' }}
                />
              </a>
            </div> */}
          </Col>
        </Row>
      </Col>

      {/* Mervyn */}
      <Col
        xs={{ span: 24, order: 3 }}
        sm={{ span: 24, order: 3 }}
        md={{ span: 8, order: 3 }}
        lg={{ span: 8, order: 3 }}
        xl={{ span: 8, order: 3 }}
      >
        <Row gutter={16}>
          <Col xs={6} sm={6} md={24} lg={24} xl={24}>
            <div className="Memoji">
              <StaticImage src="../../images/memoji-jeff.png" alt="Mervyn" />
            </div>
          </Col>
          <Col xs={18} sm={18} md={24} lg={24} xl={24}>
            <div className="NameAndRole">
              <h4>Mervyn</h4>
              <span>Director, Business</span>
            </div>
            <p className="ShortDesc">
              An accomplished business leader with full-on grit, Mervyn's penchant for marketing & growth has driven
              multiple businesses to grow and succeed.
            </p>
            {/* <div className="SocialLinks">
              <a href="https://www.linkedin.com/in/jeffrey-lee-01014391/" target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../images/icon-linkedin.png"
                  layout="fixed"
                  alt="icon"
                  style={{ flex: '0 0 auto', display: 'inline-block', marginRight: '1em' }}
                />
              </a>
              <a href="https://www.instagram.com/jeffreylee.design/" target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../images/icon-instagram.png"
                  layout="fixed"
                  alt="icon"
                  style={{ flex: '0 0 auto', display: 'inline-block' }}
                />
              </a>
            </div> */}
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export default Team;
